<template>
    <div>
        <div ref="mglMapRef" style="width: 100%; height: 800px"/>
    </div>
</template>

<script>
    import mapboxgl from 'mapbox-gl'
    import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
    import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';

    export default {
        name: "mapbox",
        data() {
            return {
                accessToken: 'pk.eyJ1IjoiY2xvemhvbWVtYXAiLCJhIjoiY2pxN2V1OXgyMGRlejQ4cXF6cWpseXVvNiJ9.NhSFVdGmrzuwZlSDbWP2xQ',
                // 地图对象
                map: null,
                longitude: null,
                latitude: null,
                address: ''
            }
        },
        created() {
            this.address = this.$route.query.address
            this.longitude = this.$route.query.longitude
            this.latitude = this.$route.query.latitude

        },
        mounted() {
            this.initMap()
        },
        methods: {
            /**
             * 初始化地图
             */
            initMap() {
                const point = [this.longitude, this.latitude]
                this.map = new mapboxgl.Map({
                    accessToken: this.accessToken,
                    container: this.$refs.mglMapRef,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: point,
                    zoom: 15, // starting zoom
                    pitch: 50
                })

                // create the popup
                const popup = new mapboxgl.Popup({offset: 25}).setText(this.address);
                popup.setLngLat(point).addTo(this.map)

// Create a default Marker and add it to the map.
                new mapboxgl.Marker({color: 'red'})
                    .setLngLat(point)
                    .addTo(this.map);

                let directions = new MapboxDirections({
                    accessToken: this.accessToken,
                    unit: 'metric',
                    profile: 'mapbox/walking',
                    alternatives: true,
                    interactive: true,		//是否允许交互，点击改变B
                    zoom: 8,
                    placeholderOrigin: this.address,
                    language:'zh-Hans',
                    flyTo: false
                });

                this.map.addControl(directions, 'top-left');
                //
                this.map.on('load', () => {
                    console.info('map on load...')
                    directions.setOrigin(point)
                });
            },

        }
    }
</script>

<style scoped lang="scss">

</style>
